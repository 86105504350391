<template>
  <ion-page id="couponDetail">
    <ion-content :fullscreen="true">
      <topbar class="gradient30 fixed" :bigtitle="false" :showBack="true" :showClose="false" :share="true" :shareData="shareData"></topbar>

      <div class="content">
        <div class="coupon-img" :style="imgStyle">
          <img v-lazy="couponDetail.img" :key="couponDetail.img" />
        </div>
        <div class="coupon-info">
          <div class="coupon-type">
            <img src="@/assets/couponIcon.png" />
            <span>E-Coupon</span>
          </div>

          <div class="coupon-name main-color font-24 Bold">
            <span>{{ couponDetail.name }}</span>
          </div>

          <div class="hr"></div>

          <div class="period">
            <label class="Bold">Collection period</label>
            <span class="font-16">
              {{ formatDate(couponDetail.collectStartTime, $store.state.form.merchantDetail.dateFormat) }}
              -
              {{ formatDate(couponDetail.collectEndTime, $store.state.form.merchantDetail.dateFormat) }}
            </span>
          </div>

          <div class="period">
            <label class="Bold">Redemption Period</label>
            <span class="font-16">
              {{ formatDate(couponDetail.redeemStartTime, $store.state.form.merchantDetail.dateFormat) }}
              -
              {{ formatDate(couponDetail.redeemEndTime, $store.state.form.merchantDetail.dateFormat) }}
            </span>
          </div>
        </div>
        <div class="tandC">
          <div class="title Bold">Offer details</div>
          <showMore :content="couponDetail.descr" :showHeight="!$store.state.isPC ? 70 : 68"></showMore>
        </div>

        <!-- <div class="btn-group" v-if="couponDetail.canRedeem">
          <ion-button color="primary" @click="redeemCoupon">Redeem</ion-button>
        </div> -->
      </div>
      <div class="height50"></div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import config from "../config";
import topbar from "@/components/topbar.vue";
import showMore from "@/components/showMore.vue";

export default {
  name: "couponUserDetail",
  mixins: [config.globalMixin],
  data() {
    return {
      couponDetail: {},
      scrollTop: 0,
      imgStyle: "",
      shareData: {},
    };
  },
  components: { IonContent, IonPage, topbar, showMore },
  methods: {
    showMore(type) {
      console.log(this.$refs[type]);
      this.$refs[type].style.height = "auto";
    },
  },
  ionViewDidEnter() {
    this.couponDetail = this.$store.state.form.userCouponDetail;
    this.couponDetail.collectStartTime = this.couponDetail.collectStartTime.replaceAll("-", "/");
    this.couponDetail.collectEndTime = this.couponDetail.collectEndTime.replaceAll("-", "/");
    this.couponDetail.redeemStartTime = this.couponDetail.redeemStartTime.replaceAll("-", "/");
    this.couponDetail.redeemEndTime = this.couponDetail.redeemEndTime.replaceAll("-", "/");
    this.shareData = {
      title: this.$store.state.appName + " - Download and Enjoy Exclusive Offers",
      description: `[ACT NOW] ${this.couponDetail.name} coupon available until stocks last! Download ${this.$store.state.appName}  and grab it now.`,
      image: this.couponDetail.img,
    };
    this.$store.state.exitApp = false;

    this.sendGAPageView("User eCoupon Detail");
  },
};
</script>
